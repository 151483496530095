import { Link } from "react-router-dom"
import { NavLinkMaker } from "../hepers/blockmaker";
import { hvnUriRoot } from "../core/defines";

function PcNav(){
    const NAV_TOP_PC = [{nameJp: "トップページ", nameEn: "TOP", path: "/home", blank: false, img: ""},
                        {nameJp: "スケジュール", nameEn: "SCHEDULE", path: "/schedule", blank: false, img: ""},
                        {nameJp: "新人情報",     nameEn: "NEW FACE", path: "/newface", blank: false, img: ""},
                        {nameJp: "在籍女性",     nameEn: "CASTS", path: "/allcasts", blank: false, img: ""},
                        {nameJp: "システム",     nameEn: "SYSTEM", path: `${hvnUriRoot}/system/?of=y`, blank: true},
                        {nameJp: "アクセス",     nameEn: "ACCESS", path: "/access", blank: false, img: ""},
                        {nameJp: "写メ日記",     nameEn: "Diary", path: `${hvnUriRoot}/diarylist/?of=y`, blank: true},
                        {nameJp: "動画一覧",     nameEn: "Movie", path: `${hvnUriRoot}/shopmovie/?of=y`, blank: true},
                        {nameJp: "女子求人",     nameEn: "Recruit", path: "https://kanto.qzin.jp/tulipgt/?v=official", blank: true,
                         tagForced: true,  requireTag: "vanilla"},
    ];

    return(
        <div id="pc-header" className="pc">
            <nav className="wrapper-pcnav">
                <ul className="pcnav txt-center">
                    {NAV_TOP_PC.map((el)=>{
                        return(
                            <li key={el.nameEn}>
                                <NavLinkMaker data={el}>
                                    {el.nameJp}<span>{el.nameEn}</span>
                                </NavLinkMaker>
                            </li>
                        )
                    })}
                </ul>
            </nav>
        </div>
    );

}

export default PcNav;